<script>
import Input from "@/components/Input.vue";
import Head from "./../../components/Head.vue";
import SectionTitle from "./../../components/SectionTitle.vue";
import Button from "@/components/Button.vue";
import Section from "@/components/Section.vue";
import Table from "./../../components/Table.vue";
import Select from "@/components/Select.vue";
import ReturnToPage from "@/components/ReturnToPage.vue";
import Loader from "./../../components/Loader.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';/*  */

export default {
  props: ["userData"],
  data() {
    return {
      loader: true,
      view: "list-contracts",
      hasNextPage: undefined,
      itemsPerPage: 10,
      actualPage: 0,
      totalItems: 0,
      totalPages: 0,
      searchTermsFilter: "",
      tableHeaders: [
        "ID",
        "Número do contrato",
        "Empresa",
        "Jovem Aprendiz",
        "Matrícula CBO",
      ],
      tableActions: ["document-text", "pencil-square"],
      tableContents: [],
      tableContentsFullInfos: [],
      tableContentsFullInfosSelectedUser: [],
      editContract: {
        apprentice_id: "",
        company_id: "",
        cbo_id: "",
        period_of: "",
        period_until: "",
        contract_validity: "",
        contract_number: undefined,
        description: "",
      },
      youngApprenticesSelect: [],
      companySelect: [],
      cbosSelect: [],
      generateContract: false,
      listContractInfos: undefined,
      updateContractInfosId: undefined,
      editSelectedContract: {
        description: "",
      }
    };
  },
  created() {
    this.getNextPageApprentices();
  },
  methods: {
    notify(text, type) {
      toast(text, {
        type:
          type == "info"
            ? "info"
            : type == "warning"
            ? "warning"
            : type == "error"
            ? "error"
            : type == "success"
            ? "success"
            : "default",
      });
    },
    getActualDate() {
      // Cria um novo objeto Date com a data atual
      var dataAtual = new Date();

      // Pega o dia, o mês e o ano
      var dia = String(dataAtual.getDate()).padStart(2, '0'); // Adiciona zero à esquerda se o dia for menor que 10
      var mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda se o mês for menor que 10
      var ano = dataAtual.getFullYear();

      // Junta dia, mês e ano no formato dd/mm/yyyy
      var dataFormatada = dia + '/' + mes + '/' + ano;

      return dataFormatada; // Exibe a data formatada
    },
    getNextPageApprentices() {
      this.loader = true;

      this.actualPage += 1;

      this.$axios
        .get(
          `/contracts?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`
        )
        .then((res) => {
          console.log(res);

          const data = res.data.data.data;

          this.tableContentsFullInfos = data;

          var arr = [];

          data.forEach((element) => {
            arr.push({
              id: element.id,
              contract_number: element.contract_number,
              social_reason_company: element.company.company_data.social_reason_company,
              young_apprentice_name: element.young_apprentice.name,
              cbo: element.cbo.code,
            });
          });

          this.tableContents = arr;

          res.data.data.last_page > 1
            ? (this.hasNextPage = true)
            : (this.hasNextPage = false);

          this.totalItems = res.data.data.total;
          this.totalPages = res.data.data.last_page;

          this.loader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listApprenticesActions(event) {
      if (event.eventType == "document-text") {
        this.loader = true;
        
        this.$axios.get(`contracts/${event.data.id}`)
        .then(res => {
            this.listContractInfos = res.data.data;
            this.editContract.contract_number = res.data.data.contract_number;

            this.generateContract = true;

            this.loader = false;
        })
        .catch((err) => {
            this.notify('Ocorreu um erro e não foi possível processar o contrato!', 'error');
            this.loader = false;
            return;
        });

        this.view = "download-contracts";
      }

      if (event.eventType == "pencil-square") {
        this.updateContractInfosId = event.data.id;
        
        this.loader = true;

        this.$axios.get(`contracts/${this.updateContractInfosId}`)
        .then((res) => {
          this.editSelectedContract.description = res.data.data.description;

          this.view = "edit-contracts"

          return this.loader = false;
        })
        .catch(err => {
          this.notify('Não foi possível localizar esse contrato!', 'error');

          this.loader = false;
          return;
        });
      }
    },
    updateContract() {
      this.loader = true;

      this.$axios.put(`contracts/${this.updateContractInfosId}`, {
        "description": this.editSelectedContract.description
      })
      .then(res => {
        this.notify('Contrato atualizado com sucesso!', 'success');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        this.notify('Não foi possível atualizar os dados do contrato!', 'error');

        return this.loader = false;
      });
    },
    actionPaginationArrows(event) {
      console.log(event);
      if (event == "previous") {
        this.actualPage -= 2;

        this.getNextPageApprentices();
      }

      if (event == "next") {
        this.getNextPageApprentices();
      }
    },
    actionPaginationPages(event) {
      this.actualPage = event - 1;

      this.getNextPageApprentices();
    },
    getApprenticesFilter() {
      this.actualPage = 0;

      this.getNextPageApprentices();
    },
    addMonthlysToDate(date, quantitys) {
      // Converte a string para um objeto Date
      let data = new Date(date);

      // Adiciona 12 meses ao mês atual
      data.setMonth(data.getMonth() + parseInt(quantitys));

      // Formata a data no formato yyyy-mm-dd
      let ano = data.getFullYear();
      let mes = String(data.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11, por isso adicionamos 1
      let dia = String(data.getDate()).padStart(2, '0');

      return this.editContract.period_until = `${ano}-${mes}-${dia}`;
    },
    editYoungApprenticies() {
      if (this.view == "add-contracts") {
        console.log(this.editCBO);
        if (!this.editCBO.code) {
          return this.notify("Preencha o campo de código e função!", "error");
        }

        this.loader = true;

        this.$axios
          .post("cbos", this.editCBO)
          .then((res) => {
            this.notify("Empresa criada com sucesso!", "success");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) => {
            this.notify("Ocorreu um erro durante a criação da empresa!", "error");
            this.loader = false;
          });
      }

      if (this.view == "edit-contracts") {
        this.loader = true;

        this.$axios
          .put(`cbos/${this.tableContentsFullInfosSelectedUser.id}`, this.editCBO)
          .then((res) => {
            this.notify("Dados da empresa atualizados com sucesso!", "success");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((err) => {
            this.notify(
              "Ocorreu um erro durante a atualização de dados da empresa!",
              "error"
            );
          });
      }
    },
    viewAddContracts() {
      this.loader = true;

      this.$axios
        .get("contracts/data/to_contract")
        .then((res) => {
          const data = res.data.data;
          console.log(data);

          this.editContract.apprentice_id = data.young_apprentices[0].id;

          data.young_apprentices.forEach((element) => {
            this.youngApprenticesSelect.push({
              name: element.name,
              value: element.id,
            });
          });

          this.editContract.company_id = data.companies[0].user_id;

          data.companies.forEach((element) => {
            this.companySelect.push({
              name: element.social_reason_company,
              value: element.user_id,
            });
          });

          this.editContract.cbo_id = data.cbos[0].id;

          data.cbos.forEach((element) => {
            this.cbosSelect.push({
              name: element.code,
              value: element.id,
            });
          });

          this.view = "add-contracts";
          this.loader = false;
        })
        .catch((err) => {
          this.notify(
            "Ocorreu um erro ao tentar abrir a tela de cadastro de contratos!",
            "error"
          );
          this.loader = false;
        });
    },
    generateFullContract() {
      if (!this.editContract.period_of || !this.editContract.contract_validity) {
        return this.notify('Para gerar um contrato, selecione o período de validade!', 'error');
      }

      this.loader = true;

      this.addMonthlysToDate(this.editContract.period_of, this.editContract.contract_validity);

      this.$axios
        .get(
          `contracts/data/to_make_contract/${this.editContract.apprentice_id}/${this.editContract.company_id}/${this.editContract.cbo_id}`
        )
        .then((res) => {
            this.listContractInfos = res.data.data;

            this.editContract.contract_number = `${res.data.data.last_contract_id + 1}/2025`;
            this.generateContract = true;

            console.log(this.listContractInfos);

            this.editContract.description = `<p style="text-align: left; margin-bottom: 20px;">
            &nbsp; Pelo presente instrumento de contrato que entre si fazem, as partes acima
            qualificadas que resolvem firmar e estabelecer as seguintes cláusulas que regerão
            as condições contratuais, que se comprometem a cumprir até o seu termo final, com
            efeito de título executivo extrajudicial, para todos os fins e efeitos de direito.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Primeira</strong> – A EMPREGADORA admite, como seu empregado, o
            APRENDIZ acima qualificado, conforme dispõe o Artigo 428 da CLT,
            <strong>comprometendo-se a proporcionar-lhe matrícula e frequência</strong> no
            curso de aprendizagem..
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Segunda</strong> – A aprendizagem desenvolver-se-á em duas fases
        concomitantes, sendo a teórica na entidade <strong>QUALIFICADORA </strong>e a
        prática profissional no estabelecimento do EMPREGADORA.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Terceira</strong> – A vigência do presente contrato será de ${ this.editContract.contract_validity }
        meses, ininterruptos, compreendendo o período de ${ this.editContract.period_of.split("-").reverse().join("/") } a ${ this.editContract.period_until.split("-").reverse().join("/") }. O
        término está vinculado ao término do curso, portanto, o contrato não é passível de
        prorrogação. A Formação Profissional se dará da seguinte maneira:
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <u>Fase teórica</u> (400) horas:  sendo de 4 (quatro) horas semanais, cumpridas
        no Instituto Aprender de Qualificação – IAQ, toda  ${ this.listContractInfos.young_apprentice.young_apprentice_data.day_theoretical }-feira das ${ this.listContractInfos.young_apprentice.young_apprentice_data.hours_phase_theoretical },
        sendo que, no período de ${ this.listContractInfos.young_apprentice.young_apprentice_data.period_of_theoretical_phase.split("-").reverse().join("/") } a ${ this.listContractInfos.young_apprentice.young_apprentice_data.period_until_theoretical_phase.split("-").reverse().join("/") }, serão cumpridas 40 de aulas
        teóricas, exclusivamente na Qualificadora. A atuação do APRENDIZ será na função de
        acordo com o <strong>CBO ${ this.listContractInfos.cbo.code }</strong>, abrangendo as seguintes atividades:
        ${ this.listContractInfos.cbo.description }
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <u>Fase Prática:</u> a jornada de trabalho do APRENDIZ será de
        <strong>${ this.listContractInfos.young_apprentice.young_apprentice_data.working_day }</strong> horas diárias, perfazendo o total de ${ this.listContractInfos.young_apprentice.young_apprentice_data.work_days_select == 'segunda-sexta' ? this.listContractInfos.young_apprentice.young_apprentice_data.working_day * 5 : this.listContractInfos.young_apprentice.young_apprentice_data.working_day * 6 } horas semanais, de
        prática profissional a serem cumpridas na EMPREGADORA, vedadas a prorrogação e a
        compensação da jornada, inclusive nas hipóteses previstas nos incisos I e II do
        artigo 413 da CLT. O horário de trabalho do jovem na EMPREGADORA será de ${this.listContractInfos.young_apprentice.young_apprentice_data.work_schedule}, de ${ this.listContractInfos.young_apprentice.young_apprentice_data.work_days_select == 'segunda-sexta' ? 'Segunda-feira à Sexta-feira' : this.listContractInfos.young_apprentice.young_apprentice_data.work_days_select == 'segunda-sabado' ? 'Segunda-feira à Sábado' : '~' }, a exceção do dia de curso.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <u>Total do Programa:</u> ${this.listContractInfos.young_apprentice.young_apprentice_data.working_day} horas diárias, de ${ this.listContractInfos.young_apprentice.young_apprentice_data.program_total_contract == 'segunda-sexta' ? 'Segunda-feira à Sexta-feira' : this.listContractInfos.young_apprentice.young_apprentice_data.program_total_contract == 'segunda-sabado' ? 'Segunda-feira à Sábado' : '~' }, totalizando ${ this.listContractInfos.young_apprentice.young_apprentice_data.program_total_contract == 'segunda-sexta' ? this.listContractInfos.young_apprentice.young_apprentice_data.working_day * 5 : this.listContractInfos.young_apprentice.young_apprentice_data.working_day * 6 }
        horas semanais. As horas de formação teórica são consideradas como horas
        efetivamente trabalhadas e integram para todos os fins, a jornada de trabalho do
        aprendiz.
        </p>

        <p style="text-align: left;">
            <strong>Cláusula Quarta – </strong>A QUALIFICADORA obriga-se a:
        </p>
        <p style="text-align: left;">
          a. Executar os Programas de Aprendizagem, ministrando os conteúdos teóricos,
            metodologicamente organizados conforme Calendário Pedagógico anexado ao
            presente Contrato, garantindo a articulação e a complementaridade entre a
            aprendizagem teórica e prática, acompanhando a execução das atividades
            práticas desenvolvidas no âmbito da EMPREGADORA;
        </p>
        <p style="text-align: left;">
          b. Acompanhar e monitorar o desenvolvimento do Programa de Aprendizagem, mantendo
            mecanismos de controle da frequência e aproveitamento do APRENDIZ nas
            atividades teóricas e práticas, de forma a garantir que as atividades práticas
            estejam em conformidade com o Programa de Aprendizagem previamente traçado;
        </p>
        <p style="text-align: left;">
            c. Acompanhar mensalmente a frequência do APRENDIZ às aulas do ensino regular de
            educação;
        </p>
        <p style="margin-bottom:30px; text-align:left;>
            d. Conceder ao APRENDIZ “Certificado de Qualificação Profissional” de conclusão
            regular e aproveitamento do curso de aprendizagem teórica, desde que o
            APRENDIZ tenha participado de forma ativa, com presença em pelo menos 75% das
            aulas do Programa de Aprendizagem.
        </p>

        <div style="margin-bottom:30px"></div>

        <p style="text-align: left; margin-top:30px">
            <strong>Cláusula Quinta – </strong>A EMPREGADORA obriga-se a<strong>:</strong>
        </p>
        <p style="text-align:left;">
          a. Registrar na Carteira de Trabalho e Previdência Social do APRENDIZ, a vigência
            do presente CONTRATO DE APRENDIZAGEM;
        </p>
        <p style="text-align:left;">
            b. Conceder férias ao APRENDIZ atendendo às necessidades da Empresa, porém em
            conformidade com as “férias escolares” e deverão ser comunicadas com
            antecedência à entidade QUALIFICADORA e do APRENDIZ seguindo as normas
            pertinentes previstas na CLT.
        </p>
        <p style="text-align:left;">
            c. Remunerar o Aprendiz, mensalmente, com base no salário-mínimo hora, vigente,
            conforme previsto nos termos do parágrafo 2º do Artigo 428 da CLT, salvo
            condição mais favorável, convenção ou acordo coletivo da categoria. Sendo, no
            caso, a remuneração corresponde ao valor de
            <strong>${ this.listContractInfos.young_apprentice.young_apprentice_data.total_hours_phase_theoretical }</strong>.
        </p>
        <p style="text-align:left;">
            d. Fornecer vale-transporte para a locomoção do APRENDIZ entre sua residência e a
            entidade qualificadora, e entre sua residência e a Empresa, concomitantemente
            com outros benefícios proporcionados pela EMPREGADORA a seus demais
            funcionários por força de instrumento coletivo de trabalho da categoria,
            quando houver.
        </p>
        <p style="text-align:left;">
            e. Comunicar, periodicamente, à QUALIFICADORA as ausências injustificadas,
            dificuldade de adaptação e desempenho insuficiente do APRENDIZ no exercício da
            função/atividade, bem como qualquer outra ocorrência considerada grave e
            prejudicial ao bom desempenho e ao cumprimento do presente Contrato;
        </p>
        <p style="text-align:left;">
            f. Informar ao Instituto Aprender de Qualificação - IAQ, os desligamentos mensais
            ocorridos, até o dia 15 de cada mês para evitar cobrança de taxa
            administrativa no mês subsequente. As Taxas administrativas referentes aos
            desligamentos informados após o dia 15 de cada mês serão cobradas em sua
            totalidade no mês subsequente.
        </p>
        <p style="text-align:left;">
            g. Garantir ao APRENDIZ todos os direitos trabalhistas e previdênciarios
            previstos por lei.
        </p>
        <p style="text-align:left;">
            h. Proporcionar ao APRENDIZ formação técnico-profissional metódica, por meio de
            atividades práticas correlacionadas com os conteúdos teóricos previstos no
            programa de aprendizagem, em ambiente adequado, que apresente condições de
            segurança e saúde, em conformidade com os requisitos do Artigo 405 da CLT e
            das Normas Regulamentadoras relativas à Segurança e Medicina
        </p>
        <p style="text-align:left;">
            i. Proporcionar ao APRENDIZ qualificação metódica, liberando e estimulando o
            APRENDIZ para o cumprimento do curso teorico no Instituto Aprender de
            Qualificação – IAQ, semanalmente, realizando todas as atividades.
        </p>
        <p style="text-align:left;">
            j. Designar profissional com delegação de competência para acompanhar as
            atividades desenvolvidas pelo APRENDIZ, bem como para ser o interlocutor da
            EMPREGADORA junto ao Instituto Aprender de Qualificação – IAQ.
        </p>

        <div style="margin-bottom:30px"></div>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Sexta </strong>– A EMPREGADORA declara conhecer toda legislação
        pertinente ao objeto do presente contrato, bem como se compromete a cumprir os
        dispositivos legais preconizados nos artigos 428 a 433 da Consolidação das Leis do
        Trabalho, com a alteração dada pela Lei nº 10.097/00, como também o Decreto nº
        6.481 de 2008.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Sétima</strong> – Sempre que o APRENDIZ deixar de comparecer à
        entidade qualificadora, durante a fase escolar da aprendizagem, ou ao
        estabelecimento da EMPREGADORA durante o período de prática profissional, sem
        justificativa fundamentada, perderá o salário dos dias faltosos.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Oitava</strong> – Durante o período de recesso escolar, o
        APRENDIZ poderá ser convocado(a) pela EMPREGADORA para prestação de serviços em
        seu estabelecimento, observando-se a Consolidação das Leis do Trabalho no que
        concerne a férias e limites de trabalho diário.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Paragrafo Único</strong> – No caso da empresa convocar o aprendiz, durante
        o recesso escolar, quando não haverá carga horária teórica, para exercer somente
        atividades práticas no estabelecimento, o limite máximo da jornada diária será de
        6 (seis) horas sem prejuízo à sua remuneração.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Nona – </strong>São de exclusiva responsabilidade da EMPREGADORA
        as obrigações decorrentes das leis trabalhistas, previdenciárias, tributárias, ou
        quaisquer outras, por mais especiais que sejam, com relação ao
        <strong>APRENDIZ</strong>.
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Décima </strong> – O APRENDIZ obriga-se a:
        </p>
        <p style="text-align:left">
          a. Executar, com zelo e diligência, as tarefas necessárias ao cumprimento das
            atividades desenvolvidas na EMPREGADORA, na QUALIFICADORA e sua formação
            profissional;
        </p>
        <p style="text-align:left">
            b. Cumprir, com exatidão, a jornada de atividades teóricas e práticas
            desenvolvidas para o bom desempenho do Programa de Aprendizagem , conforme
            descrito na Cláusula Terceira;
        </p>
        <p style="text-align:left">
            c. Participar regularmente das aulas e demais atos promovidos pela Qualificadora,
            bem como a cumprir o Regimento e as disposições disciplinares previstas no
            Programa de Aprendizagem;
        </p>
        <p style="text-align:left">
            d. Frequentar as aulas do ensino regular de educação curricular a que estiver
            matriculado, apresentando documento comprobatório, sempre que solicitado;
            Cumprir a totalidade da jornada semanal de trabalho na EMPREGADORA quando de
            folgas de aulas teóricas.
        </p>

        <div style="margin-bottom:30px"></div>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Décima Primeira – </strong> A LGPD (Lei Geral de proteção de
        dados) O (a) APRENDIZ está ciente do tratamento de seus Dados Pessoais realizado
        pela EMPREGADORA para finalidades relacionadas à relação empregatícia. “Dados
        Pessoais” significam as informações relacionadas a pessoa natural identificada ou
        identificável, nos termos da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados
        Pessoais – “LGPD”). Parágrafo Primeiro: O(a) APRENDIZ reconhece que a EMPREGADORA
        poderá tratar seus Dados Pessoais, independentemente de seu consentimento: 
        </p>
        <p style="text-align:left">
          a) Para
        o cumprimento de obrigação legal ou regulatória imposta à EMPREGADORA ou a
        terceiro;
        </p>
        <p style="text-align:left">
          b) Quando necessário para a execução do presente contrato de trabalho;
        </p>
        <p style="text-align:left">
        c) Para o exercício regular de direitos da EMPREGADORA em processo judicial,
        administrativo ou arbitral;
        </p>
        <p style="text-align:left">
          d) Para a proteção da vida ou da incolumidade física
        do(a) APRENDIZ ou de terceiro; e/ou e) Quando necessário para atender aos
        interesses legítimos da CONTRATANTE ou de terceiro, observando as restrições
        estabelecidas pela legislação aplicável. Parágrafo Segundo: O(a) APRENDIZ tem
        direito a requerer da EMPREGADORA, a qualquer momento e mediante requisição: 
        </p>
        <p style="text-align:left; padding-left:30px">
          a)Confirmação da atividade de tratamento de Dados Pessoais; 
        </p>
        <p style="text-align:left; padding-left:30px">
         b) Acesso aos Dados Pessoais; 
        </p>
        <p style="text-align:left; padding-left:30px">
         c) Correção de Dados Pessoais incompletos, inexatos ou desatualizados;
        </p>
        <p style="text-align:left; padding-left:30px">
        d) Anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários,
        excessivos ou tratados em desconformidade com o disposto na legislação brasileira;  
        </p>
        <p style="text-align:left; padding-left:30px">
        e) Portabilidade de Dados Pessoais, conforme aplicável nos termos da legislação
        brasileira; 
        </p>
        <p style="text-align:left; padding-left:30px">
          
        f) Eliminação dos Dados Pessoais tratados com o consentimento do o(a)
        APRENDIZ, observando as restrições da legislação brasileira; 
        </p>
        <p style="text-align:left; padding-left:30px">
        g) Informação das
        entidades públicas e privadas com as quais a EMPREGADORA realizou uso
        compartilhado de Dados Pessoais; 
        e 
        </p>
        <p style="text-align:left; padding-left:30px">
        e h) Informação sobre a possibilidade de não
        fornecer consentimento e sobre as consequências da negativa, assim como a
        possibilidade de revogação do consentimento. Parágrafo Terceiro: É possível que
        haja circunstâncias que não autorizem à EMPREGADORA dar atendimento ao exercício
        de algumas das prerrogativas, tais como as previstas no Parágrafo Primeiro acima,
        ou quando o fornecimento das informações puder revelar algum segredo de negócio da
        EMPREGADORA. Parágrafo Quarto: O (a) APRENDIZ reconhece que a EMPREGADORA pode
        transferir seus Dados Pessoais dentro ou para fora do território brasileiro,
        independentemente de seu consentimento, conforme permitido pela legislação
        aplicável. Parágrafo Quinto: O tratamento de Dados Pessoais do (a) APRENDIZ deverá
        ser realizado de acordo com e conforme informado pelo presente contrato de
        trabalho, pela Política de Privacidade da EMPREGADORA e a legislação aplicável.   
        </p>

        <div style="margin-bottom:30px"></div>
        
        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Décima Segunda</strong> – No exercício deste contrato de
        trabalho, o (a) APRENDIZ poderá tratar Dados Pessoais de outros empregados e/ou de
        terceiros, na forma da LGPD. Parágrafo Primeiro: O(A) APRENDIZ, na hipótese de
        tratar dados pessoais para o desempenho de suas funções, compromete-se a fazê-lo
        nos estritos termos da legislação e regulamentação aplicáveis (incluindo, mas não
        se limitando, às disposições da LGPD e eventuais regulamentações a serem
        proferidas pela Autoridade Nacional de Proteção de Dados), das políticas e
        diretrizes internas estabelecidas pela EMPREGADORA, observadas as medidas de
        privacidade e segurança de Dados Pessoais informadas pela
        <b>EMPREGADORA.</b>
        Parágrafo Segundo: O (A) APRENDIZ declara que está ciente das práticas de Proteção
        de Dados estabelecidas pela EMPREGADORA e se compromete a cumpri-las. &nbsp;
        Parágrafo Terceiro: O (A) APRENDIZ deverá comunicar imediatamente à EMPREGADORA
        sobre qualquer evento que possa configurar, de forma potencial ou efetiva,
        violação à LGPD e/ou às políticas e diretrizes internas estabelecidas pela
        EMPREGADORA envolvendo Dados Pessoais, e se comprometendo a auxiliar a EMPREGADORA
        na apuração, investigação e/ou verificação de tais violações, incluindo a adoção
        de medidas para saná-las, conforme instruído pela EMPREGADORA. &nbsp; Parágrafo
        Quarto: O (A) APRENDIZ tem ciência que o descumprimento das obrigações previstas
        nesta cláusula poderá resultar na aplicação de sanções disciplinares por parte da
        EMPREGADORA, sem prejuízo da adoção das medidas judiciais cabíveis, nos termos da
        legislação e regulamentação vigentes. &nbsp;
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Décima Terceira - </strong>São hipóteses de rescisão de contrato
        de aprendiz, conforme dispõe o Artigo 433 da CLT, o Contrato de Aprendizagem
        extinguir-se-á no seu término ou quando o APRENDIZ completar 24 (vinte e quatro)
        anos, ressalvada a hipótese prevista no parágrafo 5º. do Artigo 428 da CLT ou,
        antecipadamente, nas seguintes hipóteses: &nbsp;
        
          a) desempenho insuficiente ou inadaptação do APRENDIZ comprovado em laudo de
          avaliação elaborado pela entidade qualificadora, salvo para o APRENDIZ com
          deficiência quando desprovido de recursos de acessibilidade, de tecnologias
          assistivas e de apoio necessário ao desempenho de suas atividades (a 433 da
          CLT);
          b) falta disciplinar grave (art. 482 da CLT);
          c) ausência injustificada à escola que implique perda do ano letivo;
          d) a pedido do aprendiz.
        </ul>
        &nbsp;
        </p>

        <p style="text-align: left; margin-bottom: 20px;">
            <strong>Cláusula Décima Quarta - </strong>As partes elegem o Foro da Circunscrição
        Judiciária de Brasilia/DF, como o único competente para dirimir quaisquer dúvidas
        decorrentes deste Contrato. Assim, aceitas pelas partes as condições ora
        estabelecidas, na livre manifestação de suas vontades, assinam o presente
        instrumento de Contrato de Aprendizagem em 03 (três) vias, de igual teor e forma,
        na presença das testemunhas abaixo nomeadas e qualificadas, para que surta os
        efeitos legais. &nbsp;
        </p>
        
        <p style="text-align: center; margin: 15px 0px">Brasília, ${ this.getActualDate() }</p>`;
        console.log(this.editContract.description);
            
            this.loader = false;
        })
        .catch((err) => {
          this.notify("Ocorreu um erro ao gerar o contrato!", "error");
          this.loader = false;
        });
    },
    saveContract() {
        if (this.view == 'download-contracts') {

            var prtContent = document.getElementById("generateContract");
            var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(prtContent.innerHTML);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        }

        if (this.view == 'add-contracts') {
            this.loader = true;
    
            this.$axios.post('contracts', {
                young_apprentice_id: this.editContract.apprentice_id,
                company_id: this.editContract.company_id,
                cbo_id: this.editContract.cbo_id,
                contract_number: this.editContract.contract_number,
                description: this.editContract.description
            })
            .then(res => {
                this.notify('Contrato salvo com sucesso!', 'success');
    
                this.view = 'download-contracts';
                this.loader = false;
            })
            .catch((err) => {
                this.notify('Ocorreu um erro ao tentar salvar o contrato!', 'error');
    
                this.loader = false;
            });
        }
    },
    reload() {
        window.location.reload();
    },
  },
  components: {
    Head,
    SectionTitle,
    Input,
    Button,
    Section,
    Table,
    Select,
    ReturnToPage,
    Loader,
  },
};
</script>

<template>
  <Loader v-if="loader" />

  <section v-if="!loader">
    <Head
      :title="
        view == 'list-contracts'
          ? 'Contratos'
          : view == 'download-contracts'
          ? 'Baixar Contrato'
          : view == 'edit-contracts'
          ? 'Editar contrato'
          : 'Adicionar Contratos'
      "
    />

    <!-- Adicionar ou ver/baixar contratos -->
    <Section v-if="view == 'download-contracts' || view == 'add-contracts' || view == 'edit-contracts'">
      <ReturnToPage
        placeholder="Voltar para a listagem de contratos"
        redirectTo=""
        @clickReturn="
            reload();
          youngApprenticesSelect = [];
          companySelect = [];
          cbosSelect = [];
          generateContract = false;
          view = 'list-contracts';
        "
      ></ReturnToPage>

      <div class="form-divider"></div>

      <Select
        v-if="view == 'add-contracts'"
        label="Selecione o jovem aprendiz"
        :options="youngApprenticesSelect"
        :value="editContract.apprentice_id"
        v-model="editContract.apprentice_id"
      />

      <div class="form-divider"></div>

      <Select
        v-if="view == 'add-contracts'"
        label="Selecione a empresa"
        :options="companySelect"
        :value="editContract.company_id"
        v-model="editContract.company_id"
      />

      <div class="form-divider"></div>

      <Select
        v-if="view == 'add-contracts'"
        label="Selecione a matrícula CBO"
        :options="cbosSelect"
        :value="editContract.cbo_id"
        v-model="editContract.cbo_id"
      />

      <div v-if="view == 'add-contracts'" class="form-divider"></div>

      <Input v-if="view == 'add-contracts'" type="date" placeholder="" label="Contrato inicia em" :value="editContract.period_of" v-model="editContract.period_of" />

      <div v-if="view == 'add-contracts'" class="form-divider"></div>

      <Input v-if="view == 'add-contracts'" type="text" placeholder="12" label="Vigência do contrato (em meses)" :value="editContract.contract_validity" v-model="editContract.contract_validity" :onlyNumbers="true" />

      <div class="form-divider"></div>

      <Button
        v-if="view == 'add-contracts'"
        type="secondary"
        placeholder="Gerar contrato"
        @buttonPressed="generateFullContract"
      />

      <div class="form-divider"></div>

      <section v-if="view == 'edit-contracts'" class="bg-generated-contract">
        <QuillEditor v-if="view == 'edit-contracts'" theme="snow" v-model:content="editSelectedContract.description" contentType="html"></QuillEditor>

        <div class="divider"></div>
        <div class="divider"></div>

        <table style="height: 207px" width="1029">
          <tbody>
            <tr>
              <td style="text-align: center">
                __________________________________ <br /> EMPREGADORA
              </td>
              <td style="text-align: center">
                __________________________________ <br />  APRENDIZ
              </td>
            </tr>
            <tr>
              <td>
                <img
                  class="aligncenter wp-image-165 size-thumbnail"
                  src="https://painel.iaq.org.br/wp-content/uploads/2024/01/iaq.png"
                  alt=""
                  width="200"
                  height="200"
                />
                <p style="text-align: center">
                  Adilson Mariz de Moraes <br />
                  Presidente IAQ - Instituto Aprender de Qualificação <br />
                  CNPJ: 12.388.176.0001-41 <br />
                </p>
              </td>
              <td>
                <p style="text-align: center">__________________________________</p>
                <p style="text-align: center">RESPONSÁVEL LEGAL (APRENDIZ)</p>
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <div style="text-align: center; margin-bottom: 20px">TESTEMUNHAS:</div>
        <table style="text-align: center" width="1029">
          <tbody>
            <tr>
              <td>
                <p style="text-align: center">
                  Nome: ______________________________________________
                </p>
                <p style="text-align: center">
                  CPF: ________________________________________________
                </p>
                <p style="text-align: center">
                  Assinatura: ___________________________________________
                </p>
              </td>
              <td>
                <p style="text-align: center">
                  Nome: __________________________________________
                </p>
                <p style="text-align: center">
                  CPF: ____________________________________________
                </p>
                <p style="text-align: center">
                  Assinatura: ______________________________________
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="form-divider"></div>

        <Button
        v-if="view == 'edit-contracts'"
        type="primary"
        placeholder="Atualizar dados do contrato"
        @buttonPressed="updateContract"
        />
      </section>

      <section v-if="generateContract" id="generateContract" class="bg-generated-contract">
        <img
          style="width: 150px; max-width: 98%; text-align: center"
          src="https://painel.iaq.org.br/wp-content/uploads/2023/10/LOGO-IAQ-07-768x704-1.png"
          alt=""
          width="150"
          height="150"
        />
        <p style="text-align: center; margin-top: 30px">
          <strong><u>CONTRATO DE APRENDIZAGEM</u></strong>
        </p>
        <p style="text-align: center; margin-top: 10px;">Nº. Contrato: {{ editContract.contract_number }}</p>

        <table style="height: 280px; margin-top: 30px;" width="1087">
          <tbody>
            <tr>
              <td style="text-align: center"><strong>QUALIFICADORA</strong></td>
            </tr>
            <tr>
              <td style="text-align: left; line-height: 25px;">
                Razão Social: INSTITUTO APRENDER DE QUALIFICAÇÃO <br />
                Endereço: SGAS 604 LOTE 27 AVENIDA L2 SUL - ASA SUL - 1° ANDAR SALA 110 -
                CEP: 70.200-640 Cidade: BRASILIA - DF- <br />
                CNPJ: 12.388.176/0001-41 <br />
                Tel.:(061) - 99828-0686 / (61) 99829-0398 <br />
                Representante Legal: ADILSON MARIZ DE MORAES - Presidente
              </td>
            </tr>
          </tbody>
        </table>
        <table style="height: 280px; margin-top: 0px;" width="1088">
          <tbody>
            <tr>
              <td style="text-align: center"><strong>EMPREGADORA</strong></td>
            </tr>
            <tr>
              <td style="text-align: left; line-height: 25px;">
                Razão Social: {{ listContractInfos.company.company_data.social_reason_company }} <br />
                Endereço: {{ listContractInfos.company.company_data.address_company }} - {{ listContractInfos.company.company_data.state_city_company }} - {{ listContractInfos.company.company_data.address_zipcode_company }} <br />
                CNPJ: {{ listContractInfos.company.company_data.cnpj_company }} <br />
                Tel.: {{ listContractInfos.company.company_data.phone_company }} / {{ listContractInfos.company.company_data.phone_youth_supervisor }} <br />
                Representante Legal: {{ listContractInfos.company.company_data.name_legal_representative }} <br />
                Supervisor do Aprendiz: {{ listContractInfos.company.company_data.name_youth_supervisor }}
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <table
          style="height: 280px; margin-top: 0px; margin-bottom: 40px"
          width="1087"
        >
          <tbody>
            <tr>
              <td style="text-align: center"><strong>APRENDIZ</strong></td>
            </tr>
            <tr>
              <td style="text-align: left; line-height: 25px;">
                Nome: {{ listContractInfos.young_apprentice.name }} <br />
                Data nascimento: {{ listContractInfos.young_apprentice.young_apprentice_data.date_of_birth }} <br />
                CPF: {{ listContractInfos.young_apprentice.young_apprentice_data.document_cpf }} <br />
                CTPS: {{ listContractInfos.young_apprentice.young_apprentice_data.work_card }} SÉRIE: {{ listContractInfos.young_apprentice.young_apprentice_data.series_work_card }} <br />
                FILIAÇÃO: {{ listContractInfos.young_apprentice.young_apprentice_data.mother_name }} {{ listContractInfos.young_apprentice.young_apprentice_data.father_name ? ` e ${listContractInfos.young_apprentice.young_apprentice_data.father_name}` : '' }} <br />
                Endereço: {{ listContractInfos.young_apprentice.young_apprentice_data.address }} - {{ listContractInfos.young_apprentice.young_apprentice_data.address_city }} - {{ listContractInfos.young_apprentice.young_apprentice_data.address_zipcode }} <br />
                TEL: {{ listContractInfos.young_apprentice.young_apprentice_data.cellphone_number }}  <br />
                Matrícula no Curso: <strong>{{ listContractInfos.cbo.code }}</strong>.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="divider"></div>

        <div v-if="view == 'download-contracts'" v-html="listContractInfos.description"></div>
        <div v-if="view == 'download-contracts'" v-html="editContract.description"></div>

        <QuillEditor v-if="view == 'add-contracts'" theme="snow" v-model:content="editContract.description" contentType="html"></QuillEditor>

        <div class="divider"></div>
        <div class="divider"></div>

        <table style="height: 207px" width="1029">
          <tbody>
            <tr>
              <td style="text-align: center">
                __________________________________ <br /> EMPREGADORA
              </td>
              <td style="text-align: center">
                __________________________________ <br />  APRENDIZ
              </td>
            </tr>
            <tr>
              <td>
                <img
                  class="aligncenter wp-image-165 size-thumbnail"
                  src="https://painel.iaq.org.br/wp-content/uploads/2024/01/iaq.png"
                  alt=""
                  width="200"
                  height="200"
                />
                <p style="text-align: center">
                  Adilson Mariz de Moraes <br />
                  Presidente IAQ - Instituto Aprender de Qualificação <br />
                  CNPJ: 12.388.176.0001-41 <br />
                </p>
              </td>
              <td>
                <p style="text-align: center">__________________________________</p>
                <p style="text-align: center">RESPONSÁVEL LEGAL (APRENDIZ)</p>
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <div style="text-align: center; margin-bottom: 20px">TESTEMUNHAS:</div>
        <table style="text-align: center" width="1029">
          <tbody>
            <tr>
              <td>
                <p style="text-align: center">
                  Nome: ______________________________________________
                </p>
                <p style="text-align: center">
                  CPF: ________________________________________________
                </p>
                <p style="text-align: center">
                  Assinatura: ___________________________________________
                </p>
              </td>
              <td>
                <p style="text-align: center">
                  Nome: __________________________________________
                </p>
                <p style="text-align: center">
                  CPF: ____________________________________________
                </p>
                <p style="text-align: center">
                  Assinatura: ______________________________________
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <div class="form-divider"></div>

      <Button
        v-if="generateContract"
        type="primary"
        :placeholder="view == 'download-contracts' ? `Baixar contrato` : 'Salvar contrato'"
        @buttonPressed="saveContract"
      />
    </Section>

    <!-- Listar contratos -->
    <Section v-if="view == 'list-contracts'">
      <SectionTitle title="Gerenciar contratos" />

      <div class="form-divider"></div>

      <section class="top-add-button">
        <Button
          @buttonPressed="viewAddContracts"
          class="add-button"
          type="primary"
          placeholder="+ Adicionar contrato"
        />
      </section>

      <div class="form-divider"></div>

      <Input
        :searchButton="true"
        placeholder="Número do documento"
        label="Filtre pelo N° do documento"
        :value="searchTermsFilter"
        v-model="searchTermsFilter"
        @clickSearchButton="getApprenticesFilter"
      />

      <div class="form-divider"></div>

      <Table
        v-if="!loader"
        :hasActions="true"
        :actions="tableActions"
        :hasNextPage="hasNextPage"
        :totalItems="totalItems"
        :totalPages="totalPages"
        :actualPage="actualPage"
        :headers="tableHeaders"
        :contents="tableContents"
        @loadMore="getNextPage"
        @clickAction="listApprenticesActions"
        @paginationArrows="actionPaginationArrows"
        @paginationPages="actionPaginationPages"
      />
    </Section>
  </section>
</template>

<style scoped>
.top-add-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -55px;
}

.add-button {
  width: 300px;
  max-width: 100%;
}

.bg-generated-contract {
  width: 100%;
  border: 1px solid rgb(234, 234, 234);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.divider {
  margin-top: 30px;
  margin-bottom: 60px;
}

@media screen and (max-width: 700px) {
  .top-add-button {
    margin-top: 0px;
  }

  .add-button {
    width: 100%;
    justify-content: center;
  }
}
</style>
